<template>
  <section id="settings">
    <div class="col1">
      <h3>Settings</h3>
      <p>Update your profile</p>

      <transition name="fade">
        <p v-if="this.showSuccess" class="success">profile updated</p>
      </transition>

      <form @submit.prevent>
        <label for="userName">User Name</label>
        <input
          v-model.trim="userName"
          type="text"
          :placeholder="userProfile.userName"
          id="userName"
        />

        <label for="firstName">First Name</label>
        <input
          v-model.trim="firstName"
          type="text"
          :placeholder="userProfile.firstName"
          id="firstName"
        />

        <button @click="updateProfile()" class="button">Update Profile</button>
      </form>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      userName: '',
      firstName: '',
      showSuccess: false,
    };
  },
  computed: {
    ...mapState(['userProfile']),
  },
  methods: {
    updateProfile() {
      this.$store.dispatch('updateProfile', {
        userName: this.userName !== '' ? this.userName : this.userProfile.userName,
        firstName: this.firstName !== '' ? this.firstName : this.userProfile.firstName,
      });
      this.userName = '';
      this.firstName = '';
      this.showSuccess = true;
      setTimeout(() => {
        this.showSuccess = false;
        console.log('this.showSuccess', this.showSuccess);
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
